import React, { useState, useEffect, useRef } from 'react';
import './loader.scss';
import FadeInOut from './FadeInOut';
import chaouen from '../../assets/chaouenHeader.jpg';
import sunset from '../../assets/blogHeader.jpg';
import halong from '../../assets/halong.jpg';
import marietas from '../../assets/marietas.jpg';
import waterfall from '../../assets/waterfall.jpg';
import { CircularProgress } from '@mui/material';  // Add this line
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

const images = [chaouen, sunset, halong, marietas, waterfall];

const Loader = ({ loaded = 0, total = 0, language = 'en' }) => {
  const [currentImage, setCurrentImage] = useState(null);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const [randomIndex, setRandomIndex] = useState(0);
  const [smoothProgress, setSmoothProgress] = useState(0);
  const progressRef = useRef(0);
  const rafRef = useRef(null);

  const images = [chaouen, sunset, halong, marietas, waterfall];
  
  const messages = {
    en: [
      "Getting my camera ready...",
      "Developing the photos...",
      "Waiting at baggage claim...",
      "Sorting through memories...",
      "Finding the perfect angle...",
      "Processing the highlights...",
      "Almost there, just a few more shots...",
      "Making sure everything looks perfect..."
    ],
    es: [
      "Preparando mi cámara...",
      "Revelando las fotos...",
      "Esperando en la recogida de equipaje...",
      "Organizando los recuerdos...",
      "Buscando el ángulo perfecto...",
      "Procesando los momentos destacados...",
      "Casi listo, últimos retoques...",
      "Asegurándome que todo se vea perfecto..."
    ],
    fr: [
      "Je prépare mon appareil photo...",
      "Développement des photos...",
      "En attente à la récupération des bagages...",
      "Tri des souvenirs...",
      "Recherche du meilleur angle...",
      "Traitement des moments forts...",
      "Presque là, dernières retouches...",
      "Vérification que tout est parfait..."
    ],
    zh: [
      "准备我的相机...",
      "照片处理中...",
      "等待行李提取...",
      "整理回忆...",
      "寻找最佳角度...",
      "处理精彩时刻...",
      "快好了，就差最后一点...",
      "确保一切看起来完美..."
    ]
  };

  const actualProgress = total > 0 ? Math.min(Math.round((loaded / total) * 100), 100) : 0;

  useEffect(() => {
    const animateProgress = () => {
      const diff = actualProgress - progressRef.current;
      const step = Math.max(diff * 0.1, 0.5);
      
      if (Math.abs(diff) > 0.1) {
        progressRef.current += step;
        setSmoothProgress(Math.min(Math.round(progressRef.current), 100));
        rafRef.current = requestAnimationFrame(animateProgress);
      } else {
        progressRef.current = actualProgress;
        setSmoothProgress(actualProgress);
      }
    };

    rafRef.current = requestAnimationFrame(animateProgress);
    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, [actualProgress]);

  useEffect(() => {
    setCurrentImage(images[randomIndex]);
    
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentMessageIndex(prev => (prev + 1) % messages[language || 'en'].length);
        setRandomIndex(prev => (prev + 1) % images.length);
        setFade(true);
      }, 500);
    }, 6000);

    return () => clearInterval(interval);
  }, [language, randomIndex]);

  return (
    <div className="loader-wrapper">
      <div className={`header ${fade ? 'fade' : 'fade-out'}`}>
        <img 
          src={currentImage} 
          alt="" 
          className="loading-image"
        />
        <div className="header-contents">
          <FadeInOut show={true} duration={500}>
            <div className="loading-indicator">
              <CircularProgress 
                variant="determinate" 
                value={smoothProgress} 
                size={80} 
                thickness={5}
                color="inherit"
              />
            </div>
            <h1 className="loading-text">
              {smoothProgress === 0 && messages[language || 'en'][currentMessageIndex]}
              {smoothProgress === 100 && 'Complete'}
              {smoothProgress !== 100 && smoothProgress !== 0 && (
                <>
                  {smoothProgress}%
                  <br />
                  {messages[language || 'en'][currentMessageIndex]}
                </>
              )}
            </h1>
          </FadeInOut>
        </div>
      </div>
    </div>
  );
};

export default Loader;


import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FormGroup, FormControlLabel, Switch } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Carousel } from 'react-responsive-carousel';
import SwipeIcon from '@mui/icons-material/Swipe';
import SwipeDownIcon from '@mui/icons-material/SwipeDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Header from './header/Header';
import Loader from '../loader/Loader';
import Footer from '../footer/Footer';
import { trackInteraction, EventTypes } from './tracking-utils';
import LoaderSpinner from './loader-temp/LoaderSpinner';
import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './gallery.scss';
import './collection.scss';
import { m } from 'framer-motion';

const API_URL = "https://www.api.kenankorn.com";
const ASPECT_RATIO = 3 / 4; // Typical photo aspect ratio
const IMAGES_PER_PAGE = 80;


const useBackgroundFetch = (currentPage, perPage, allImages) => {
  const prefetchNextPage = useCallback(async () => {
    const nextPageStart = currentPage * perPage;
    const nextPageImages = allImages.slice(nextPageStart, nextPageStart + perPage);
    
    // Prefetch next page images
    const prefetchPromises = nextPageImages.map(image => 
      new Promise((resolve) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = resolve; // Don't let errors block
        img.src = image.file_src;
      })
    );

    await Promise.all(prefetchPromises);
  }, [currentPage, perPage, allImages]);

  useEffect(() => {
    if (allImages.length > (currentPage * perPage)) {
      prefetchNextPage();
    }
  }, [currentPage, prefetchNextPage]);
};


const ImageModal = ({ image, index, isOpen, onClose, onNavigate }) => {
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="modal"
      onClick={(e) => {
        if (e.target.className === 'modal') onClose();
      }}
    >
      <span className="close" onClick={onClose}>&times;</span>
      <div className="modal-content-wrapper">
        <img
          className="modal-content"
          src={image.file_src}
          alt={image.name}
        />
        <div className='description'>
          <div className="iconLocation">
            <i className="locationPin fa-solid fa-city"></i>
            <p>{image.city}</p>
          </div>
          <div className="captionDescription">
            <i className="locationPin fa-solid fa-globe"></i>
            <p>{image.country}</p>
          </div>
        </div>
      </div>
      <div className="slideNavigation">
        <div
          className="leftSlide"
          onClick={() => onNavigate('prev', index)}
        >
          <i className="slideIcon fa-solid fa-arrow-left"></i>
        </div>
        <div
          className="rightSlide"
          onClick={() => onNavigate('next', index)}
        >
          <i className="slideIcon fa-solid fa-arrow-right"></i>
        </div>
      </div>
    </div>
  );
};  // ImageModal

const Gallery = () => {
  // Core state
  const [state, setState] = useState({
    allImages: [],
    currentPage: 1,
    totalPages: 1,
    currentImages: [],
    isLoading: true,
    loadedImageCount: 0,
    perPage: 80,
    skeleton: false,
    toggle: false,
    currentIndex: 0,
    modal: 'none'
  });


  const [loadingStates, setLoadingStates] = useState({
    initialLoad: true,
    imagesPreloading: true,
    allImagesLoaded: false
  });

  const [loadTracker, setLoadTracker] = useState({
    loadedCount: 0,
    totalImages: 0,
    preloadedImages: new Set()
  });

  const [imageData, setImageData] = useState({
    allImages: [],
    displayedImages: []
  });
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [loadedImages, setLoadedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [containerHeight, setContainerHeight] = useState(0);
  const containerRef = useRef(null);
  const observerRef = useRef(null);
  const [imageState, setImageState] = useState({
    allImages: [],
    displayedImages: [],
    currentPage: 1,
    perPage: IMAGES_PER_PAGE,
    isLoading: true
  });
  const [loaderState, setLoaderState] = useState({
    loaded: 0,
    total: 0,
    language: navigator.language.split('-')[0] || 'en'
  });

  // Data state
  const [data, setData] = useState({
    allImages: [],
    loadedImages: [],
    collections: [],
    currentCollection: '',
    displayMode: 'all' // 'all' | 'collection' | 'filtered'
  });
  useBackgroundFetch(state.currentPage, state.perPage, data.allImages);

  const logActivity = useCallback(async (type, detail) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;

      await fetch(`${API_URL}/interaction`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ action: `${type} ${detail}` })
      });
    } catch (error) {

    }
  }, []);

  // UI state
  const [dimensions, setDimensions] = useState({
    screenWidth: window.innerWidth,
    isSmallScreen: window.innerWidth < 785
  });


  const [imageLoadingState, setImageLoadingState] = useState({
    preloadedImages: [],
    isLoading: true,
    containerHeight: 0
  });


  // Handlers
  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    setDimensions({
      screenWidth: width,
      isSmallScreen: width < 785
    });
  }, []);


  useEffect(() => {
    trackInteraction(EventTypes.PAGE_VIEW, {
      referrer: document.referrer || 'direct',
      isInitialLoad: true,
      pageType: 'gallery'
    });

    const handleBeforeUnload = () => {
      trackInteraction(EventTypes.PAGE_EXIT, {
        timeSpent: Math.floor((Date.now() - window.performance.timing.navigationStart) / 1000)
      });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);


  useEffect(() => {
    let scrollTimeout;
    const handleScroll = () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }

      scrollTimeout = setTimeout(() => {
        trackInteraction(EventTypes.SCROLL, {
          section: data.displayMode,
          collection: data.currentCollection || 'all'
        });
      }, 1000);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  }, [data.displayMode, data.currentCollection]);

  const fetchInitialData = useCallback(async () => {
  try {
    setLoadingStates({
      initialLoad: true,
      imagesPreloading: true,
      allImagesLoaded: false
    });

    const [imagesResponse, collectionsResponse] = await Promise.all([
      fetch(`${API_URL}/images`),
      fetch(`${API_URL}/collections`)
    ]);

    if (!imagesResponse.ok || !collectionsResponse.ok) {
      throw new Error('Failed to fetch data');
    }

    const imagesData = await imagesResponse.json();
    const collectionsData = await collectionsResponse.json();
    const initialImages = imagesData.slice(0, state.perPage);

    // Set total to include both download and render stages
    setLoaderState(prev => ({
      ...prev,
      total: initialImages.length * 2,
      loaded: 0
    }));

    // First stage: Load images
    const preloadPromises = initialImages.map(image => 
      new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          setLoaderState(prev => ({
            ...prev,
            loaded: prev.loaded + 1
          }));
          resolve(image);
        };
        img.onerror = reject;
        img.src = image.file_src;
      })
    );

    await Promise.all(preloadPromises);

    // Update data states
    setData(prev => ({
      ...prev,
      allImages: imagesData,
      loadedImages: initialImages,
      collections: collectionsData
    }));

    // Second stage: Wait for render
    const renderPromises = initialImages.map((image, index) =>
      new Promise(resolve => {
        setTimeout(() => {
          setLoaderState(prev => ({
            ...prev,
            loaded: prev.loaded + 1
          }));
          resolve();
        }, 100 * index); // Stagger render tracking
      })
    );

    await Promise.all(renderPromises);

    // Small delay before completion
    await new Promise(resolve => setTimeout(resolve, 500));

    setImagesLoaded(true);
    setLoadingStates({
      initialLoad: false,
      imagesPreloading: false,
      allImagesLoaded: true
    });

  } catch (error) {
    console.error('Error fetching data:', error);
    setLoadingStates({
      initialLoad: false,
      imagesPreloading: false,
      allImagesLoaded: false
    });
    toast.error('Failed to load images');
  }
}, [state.perPage]);
  


  useEffect(() => {
    fetchInitialData();
  }, []);

  const handleImageLoad = useCallback((index) => {
    setLoadTracker(prev => {
      const newLoadedItems = new Set(prev.loadedItems || new Set());
      newLoadedItems.add(index);
      const newCount = newLoadedItems.size;
  
      // Update loader state
      setLoaderState(prev => ({
        ...prev,
        loaded: prev.loaded + 1
      }));
  
      // Load all images for the current page
      if (newCount >= prev.totalImages) {
        setImagesLoaded(true);
        setState(prev => ({
          ...prev,
          loading: false
        }));
      }
  
      return {
        ...prev,
        loadedItems: newLoadedItems,
        loadedCount: newCount
      };
    });
  }, []);
  const trackInteraction = useCallback(async (type, details = {}) => {
    // Import EventTypes from tracking-utils is already available
    const userId = localStorage.getItem('userId');
    const accessToken = localStorage.getItem('accessToken');

    if (!userId || !accessToken) {
      console.log('Missing userId or accessToken for tracking');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/track/interaction`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-User-ID': userId
        },
        body: JSON.stringify({
          type,
          details: {
            ...details,
            userId,
            path: window.location.pathname,
            timestamp: new Date().toISOString(),
            viewport: {
              width: window.innerWidth,
              height: window.innerHeight
            },
            deviceInfo: {
              type: /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(navigator.userAgent) ? 'mobile' : 'desktop',
              platform: navigator.platform,
              vendor: navigator.vendor
            }
          }
        })
      });

      if (!response.ok) {
        throw new Error(`Failed to track interaction: ${response.status}`);
      }
    } catch (error) {
      console.error('Error tracking interaction:', error);
    }
  }, []);





  const [modalState, setModalState] = useState({
    isOpen: false,
    currentImage: null,
    currentIndex: null
  });

  const handleModalOpen = useCallback((index, image) => {
    trackInteraction(EventTypes.VIEW_IMAGE, {
      imageId: image.id,
      imageName: image.name,
      imageLocation: {
        city: image.city || 'unknown',
        country: image.country || 'unknown'
      },
      collectionName: image.collection,
      position: index
    });

    setModalState({
      isOpen: true,
      currentImage: image,
      currentIndex: index
    });
  }, []);




  const handleModalClose = useCallback(() => {
    setModalState({
      isOpen: false,
      currentImage: null,
      currentIndex: null
    });
  }, []);

  const handleModalNavigation = useCallback((direction, currentIndex) => {
    const newIndex = direction === 'next' ?
      currentIndex + 1 :
      currentIndex - 1;

    if (newIndex >= 0 && newIndex < data.loadedImages.length) {
      setModalState(prev => ({
        ...prev,
        currentImage: data.loadedImages[newIndex],
        currentIndex: newIndex
      }));
    }
  }, [data.loadedImages]);

  useEffect(() => {
    if (data.allImages.length > 0) {
      // Estimate height based on viewport width and image aspect ratio
      const containerWidth = containerRef.current?.offsetWidth || window.innerWidth;
      const estimatedHeight = Math.ceil(data.allImages.length / 3) * (containerWidth / 3 * 0.75);
      setImageLoadingState(prev => ({
        ...prev,
        containerHeight: estimatedHeight
      }));
    }
  }, [data.allImages]);

  // Pre-cache images
  useEffect(() => {
    if (!data.allImages.length) return;

    const imagePromises = data.allImages.slice(0, state.perPage).map(image => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image.file_src;
        img.onload = () => resolve(image);
        img.onerror = reject;
      });
    });

    Promise.all(imagePromises)
      .then(preloadedImages => {
        setImageLoadingState(prev => ({
          ...prev,
          preloadedImages,
          isLoading: false
        }));
        setLoadedImages(preloadedImages);
      })
      .catch(error => {
        console.error('Error pre-loading images:', error);
        setImageLoadingState(prev => ({
          ...prev,
          isLoading: false
        }));
      });
  }, [data.allImages, state.perPage]);


  // Modal handlers
  const modalHandlers = {
    open: useCallback((id, imageName) => {
      logActivity('viewed image', imageName.split('/').pop());
      const modal = document.getElementById(id);
      if (modal) modal.style.display = "block";
    }, [logActivity]),

    close: useCallback(() => {
      const modals = document.getElementsByClassName("modal");
      Array.from(modals).forEach(modal => modal.style.display = "none");
    }, []),

    navigate: useCallback((direction, currentId) => {
      const nextId = direction === 'next'
        ? parseInt(currentId) + 1
        : parseInt(currentId) - 1;

      modalHandlers.close();
      const nextModal = document.getElementById(nextId);
      if (nextModal) nextModal.style.display = "block";
    }, [])
  };

  // Data fetching
  const fetchImages = useCallback(async () => {
    try {
      // Set initial loading state
      setLoadingStates(prev => ({
        ...prev,
        initialLoad: true,
        imagesPreloading: true
      }));

      const response = await fetch('https://www.api.kenankorn.com/images');
      if (!response.ok) throw new Error('Failed to fetch images');
      
      const images = await response.json();
      
      // Calculate the current page's worth of images
      const startIndex = (state.currentPage - 1) * state.perPage;
      const endIndex = startIndex + state.perPage;
      const currentPageImages = images.slice(startIndex, endIndex);

      
      // Update image data first
      setImageData(prev => ({
        ...prev,
        allImages: images,
        displayedImages: currentPageImages
      }));
      
      // Pre-load all images for the current page
      const loadPromises = currentPageImages.map(image =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(image);
          img.onerror = () => reject();
          img.src = image.file_src;
        })
      );

      // Wait for all images to load
      await Promise.all(loadPromises);

      setLoadTracker(prev => ({
        ...prev,
        totalImages: currentPageImages.length,
        loadedCount: currentPageImages.length
      }));

      setImagesLoaded(true);
      setIsLoading(false);
      setState(prev => ({ ...prev, loading: false }));
      setLoaderState(prev => ({ ...prev, loaded: currentPageImages.length }));
      setLoadingStates({
        initialLoad: false,
        imagesPreloading: false,
        allImagesLoaded: true
      });

    } catch (error) {
      console.error('Error fetching images:', error);
      // Clear loading states on error
      setLoadingStates({
        initialLoad: false,
        imagesPreloading: false,
        allImagesLoaded: false
      });
      setIsLoading(false);
      setState(prev => ({ ...prev, loading: false }));
    }
  }, [state.currentPage, state.perPage]);




  // Preload all images
  const preloadImages = useCallback((images) => {
    setLoadingStates(prev => ({ ...prev, imagesPreloading: true }));

    images.forEach((imageData) => {
      const img = new Image();

      img.onload = () => {
        setLoadTracker(prev => {
          const newPreloaded = new Set(prev.preloadedImages);
          newPreloaded.add(imageData.id);

          const newCount = newPreloaded.size;
          const percentLoaded = (newCount / prev.totalImages) * 100;

          // If all images are loaded, update loading states
          if (percentLoaded === 100) {
            setLoadingStates({
              initialLoad: false,
              imagesPreloading: false,
              allImagesLoaded: true
            });
          }

          return {
            ...prev,
            loadedCount: newCount,
            preloadedImages: newPreloaded
          };
        });
      };

      img.onerror = () => {
        console.error(`Failed to load image: ${imageData.file_src}`);
        // Still increment counter to avoid loader getting stuck
        setLoadTracker(prev => ({
          ...prev,
          loadedCount: prev.loadedCount + 1
        }));
      };

      img.src = imageData.file_src;
    });
  }, []);


  const fetchCollections = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/collections`);
      if (!response.ok) throw new Error('Failed to fetch collections');

      const collections = await response.json();
      setData(prev => ({ ...prev, collections }));
    } catch (error) {
      console.error('Error fetching collections:', error);
      toast.error('Failed to load collections');
    }
  }, []);

  // Update the handlePageChange function to prevent loader from showing
  const handlePageChange = useCallback((action) => {
    const newPage = action === "next" 
      ? state.currentPage + 1 
      : state.currentPage - 1;
  
    if (newPage < 1 || newPage > Math.ceil(data.allImages.length / state.perPage)) return;

    const startIndex = (newPage - 1) * state.perPage;
    const endIndex = Math.min(startIndex + state.perPage, data.allImages.length);
    const newDisplayedImages = data.allImages.slice(startIndex, endIndex);

    // Images should already be cached, apply immediate update
    setState(prev => ({
      ...prev,
      currentPage: newPage,
      currentImages: newDisplayedImages
    }));
    setImageData(prev => ({ ...prev, displayedImages: newDisplayedImages }));
    setData(prev => ({ ...prev, loadedImages: newDisplayedImages }));

    const section = document.querySelector('#photos');
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    trackInteraction(EventTypes.PAGE_CHANGE, {
      fromPage: state.currentPage,
      toPage: newPage,
      direction: action,
      totalImages: data.allImages.length,
      imagesPerPage: state.perPage,
    });
  }, [state.currentPage, state.perPage, data.allImages]);  

  const handleCollectionFilter = useCallback((collectionName) => {
    if (!collectionName) return;

    trackInteraction(EventTypes.VIEW_COLLECTION, {
      collectionName,
      imagesCount: data.allImages.filter(img => img.collection === collectionName).length,
      filterType: 'direct'
    });

    // Filter images and update state
    const filteredImages = data.allImages.filter(img => 
      img.collection.toLowerCase() === collectionName.toLowerCase()
    );

    setState(prev => ({
      ...prev,
      currentPage: 1
    }));

    setImageData(prev => ({
      ...prev,
      displayedImages: filteredImages
    }));

    setData(prev => ({
      ...prev,
      currentCollection: collectionName,
      displayMode: 'collection',
      loadedImages: filteredImages
    }));

    const photosSection = document.querySelector('#photos');
    if (photosSection) {
      photosSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [data.allImages, trackInteraction]);


  const resetFilters = useCallback(() => {
    const initialImages = data.allImages.slice(0, state.perPage);
    
    setState(prev => ({
      ...prev,
      currentPage: 1
    }));
  
    setImageData(prev => ({
      ...prev,
      displayedImages: initialImages
    }));
  
    setData(prev => ({
      ...prev,
      currentCollection: '',
      displayMode: 'all',
      loadedImages: initialImages
    }));
  
    // Scroll back to collections
    const collectionsSection = document.querySelector('.collectionNew');
    if (collectionsSection) {
      collectionsSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [data.allImages, state.perPage]);
  


  useEffect(() => {
    if (data.currentCollection && data.displayMode !== 'collection') {
      handleCollectionFilter(data.currentCollection);
    }
  }, [data.currentCollection, data.displayMode, handleCollectionFilter]);

  useEffect(() => {
    // Track initial page load
    trackInteraction('PAGE_LOAD', {
      referrer: document.referrer,
      loadTime: window.performance.timing.loadEventEnd - window.performance.timing.navigationStart,
      renderTime: window.performance.timing.domComplete - window.performance.timing.domLoading
    });

    // Track when user leaves page
    const handleBeforeUnload = () => {
      trackInteraction('PAGE_EXIT', {
        timeSpent: Math.floor((new Date().getTime() - window.performance.timing.navigationStart) / 1000),
        lastInteraction: new Date().toISOString()
      });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  useEffect(() => {
    let scrollTimeout;
    const handleScroll = () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        trackInteraction('SCROLL', {
          scrollDepth: `${((window.pageYOffset + window.innerHeight) / document.documentElement.scrollHeight * 100).toFixed(2)}%`,
          scrollPosition: window.pageYOffset,
          viewportHeight: window.innerHeight,
          pageHeight: document.documentElement.scrollHeight
        });
      }, 500); // Debounce scroll events
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  useEffect(() => {
    let resizeTimeout;
    const handleResizeTracking = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        trackInteraction('RESIZE', {
          newWidth: window.innerWidth,
          newHeight: window.innerHeight,
          orientation: window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'
        });
      }, 500); // Debounce resize events
    };

    window.addEventListener('resize', handleResizeTracking);
    return () => {
      window.removeEventListener('resize', handleResizeTracking);
      clearTimeout(resizeTimeout);
    };
  }, []);

  // Effects
  useEffect(() => {
    //window.scrollTo(0, 0);
    window.addEventListener('resize', handleResize);
    document.addEventListener('contextmenu', e => e.preventDefault());

    const checkInstagram = () => {
      const userAgent = navigator.userAgent;
      if (userAgent.includes('Instagram')) {
        setState(prev => ({ ...prev, showInsta: true }));
      }
    };

    const loadSavedConfig = () => {
      const savedViewMode = localStorage.getItem('site_config');
      if (savedViewMode && dimensions.screenWidth >= 480) {
        setState(prev => ({ ...prev, viewMode: savedViewMode }));
      }
    };

    checkInstagram();
    loadSavedConfig();
    fetchImages();
    fetchCollections();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, fetchCollections, dimensions.screenWidth]);

  // Update loaded images when page changes
  useEffect(() => {
    const startIndex = (state.currentPage - 1) * state.perPage;
    const endIndex = startIndex + state.perPage;
    setData(prev => ({
      ...prev,
      loadedImages: prev.allImages.slice(startIndex, endIndex)
    }));
  }, [state.currentPage, state.perPage]);

  // Update display mode when collection changes
  useEffect(() => {
    if (data.currentCollection) {
      setData(prev => ({
        ...prev,
        displayMode: 'filtered'
      }));
    }
  }, [data.currentCollection]);

  // Update view mode in local storage
  useEffect(() => {
    localStorage.setItem('site_config', state.viewMode);
  }, [state.viewMode]);

  



  // Render helpers
  const renderImage = useCallback(({ image, index }) => {
    return (
      <div
        key={image.id}
        className="imageContainer"
        onClick={() => handleModalOpen(index, image)}
      >
        <div className="img-c">
          <LazyLoadImage
            alt={`${image.city}, ${image.country}`}
            src={image.file_src}
            effect="blur"
            threshold={100}
            width="100%"
            height="100%"
            placeholderSrc="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQdHx4eHRoaHSQtJSAyVC08MTAxMTMwNjs7PjU/REdITFFNUFZgYWJeZ2JzfnZbc3JxcmD/2wBDARUXFx4aHR4eHUJBNDA0QEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQED/wAARCAAIAAoDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAb/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAX/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCdABmX/9k="
            wrapperClassName="img-wrapper"
            beforeLoad={() => {
              setIsLoading(true);
              setLoadTracker(prev => ({
                ...prev,
                totalImages: imageData.displayedImages.length
              }));
            }}
            afterLoad={() => {
              setLoadTracker(prev => {
                const newLoadedCount = prev.loadedCount + 1;
                return {
                  ...prev,
                  loadedCount: newLoadedCount,
                  loadedItems: new Set([...prev.loadedItems || [], index])
                };
              });
              setIsLoading(false);
            }}
            visibleByDefault={true}
          />
        </div>
        {state.toggle && (
          <div className="text-overlay">
            <div className="location">
              {image.city}, {image.country}
            </div>
          </div>
        )}
      </div>
    );
  }, [handleModalOpen, imageData.displayedImages.length]);
  


  const renderCollection = useCallback(({ collection, index }) => (
    <div className="collectionItem" key={index}>
      <div className="collectionPicture">
        <img src={collection.coverImage} alt={collection.name} className="imageCollection" />
      </div>
      <div className="collectionDetails">
        <div className="nameWrapper">
          <div className="nameCollection">{collection.name}</div>
        </div>
        <div className="button">
          <div className="buttonViewCollection">
            <button
              className="btn viewCollection draw-border"
              onClick={() => handleCollectionFilter(collection.name)}
            >
              View Collection
            </button>
          </div>
        </div>
      </div>
      {index === 0 && state.currentIndex === 0 && dimensions.isSmallScreen && (
        <div className="descriptionCollection">
          <div className="swipe state-item">
            <SwipeIcon className='swipe-icon' />
            Swipe to view more collections
          </div>
          <div className="scroll state-item">
            <SwipeDownIcon className='scroll-icon' />
            Scroll down to view all my photos
          </div>
        </div>
      )}
    </div>
  ), [handleCollectionFilter, state.currentIndex, dimensions.isSmallScreen]);


  const ImagePlaceholder = () => (
    <div className="imageContainer">
      <div className="img-c placeholder">
        <div className="placeholder-content" />
      </div>
    </div>
  );

  const placeholderStyles = `
  .placeholder-content {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      #f0f0f0 0%,
      #e0e0e0 50%,
      #f0f0f0 100%
    );
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
  }

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;

  // Main render
  return (
    <div className='gallery-main-wrapper'>
        {loadingStates.initialLoad && state.currentPage == 1 && (
          <Loader 
            loaded={loaderState.loaded}
            total={loaderState.total}
            language={loaderState.language}
          />
        )}



      <Header setHeaderDisplay={val => setState(prev => ({ ...prev, displayHeader: val }))} />

      {!state.loading && (
        <div className="collectionNew">
          <Carousel
            showThumbs={false}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            onChange={index => setState(prev => ({ ...prev, currentIndex: index }))}
          >
            {data.collections.map((collection, i) =>
              renderCollection({ collection, index: i })
            )}
          </Carousel>
        </div>
      )}

      <div className="navTopGallery">
        <div className="filterMethod">
          {data.displayMode !== 'all' && (
            <div className="resetAll">
              <button className="resetImages" onClick={resetFilters}>
                <i className="resetIcon fa-solid fa-rotate-left"></i>
              </button>
              <span className="resetText" onClick={resetFilters}>Reset</span>
            </div>
          )}
        </div>
      </div>

      <div className={state.viewMode} id="mainWrapper">
        <div
          ref={containerRef}
          id="photos"
          className={`photo-grid ${imagesLoaded ? 'loaded' : ''}`}
        >
          {imageData.displayedImages.map((image, index) => (
              <div
                key={image.id}
                className="imageContainer"
                onClick={() => handleModalOpen(index, image)}
              >
                <div className="img-c">
                  <LazyLoadImage
                    alt={`${image.city}, ${image.country}`}
                    src={image.file_src}
                    effect="blur"
                    width="100%"
                    height="100%"
                    placeholderSrc="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQdHx4eHRoaHSQtJSAyVC08MTAxMTMwNjs7PjU/REdITFFNUFZgYWJeZ2JzfnZbc3JxcmD/2wBDARUXFx4aHR4eHUJBNDA0QEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQED/wAARCAAIAAoDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAb/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAX/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCdABmX/9k="   
                    wrapperClassName="img-wrapper"
                    beforeLoad={() => {
                      setIsLoading(true);
                    }
                    }
                    visibleByDefault={true}
                  />
                </div>
                {state.toggle && (
                  <div className="text-overlay">
                    <div className="location">
                      {image.city}, {image.country}
                    </div>
                  </div>
                )}
              </div>
            ))}



        </div>
      </div>



      {imagesLoaded && data.displayMode === 'all' && (
        <div className="pagination-controls">
          <button
            className="prev-button load-more-button"
            disabled={state.currentPage === 1}
            onClick={() => handlePageChange("prev")}
          >
            <ArrowBackIosNewIcon /> Previous
          </button>

          <button
            className="next-button load-more-button"
            disabled={state.currentPage * state.perPage >= data.allImages.length}
            onClick={() => handlePageChange("next")}
          >
            Next <ArrowForwardIosIcon />
          </button>
        </div>
      )}

      {!state.loading && <Footer />}
      <ToastContainer />
      <ImageModal
        image={modalState.currentImage}
        index={modalState.currentIndex}
        isOpen={modalState.isOpen}
        onClose={handleModalClose}
        onNavigate={handleModalNavigation}
      />
    </div>
  );
};

export default Gallery;
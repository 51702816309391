import { useEffect, useState, useRef } from 'react';
import './client.scss';
import Header from "../../assets/headers/12.jpg";
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { saveAs } from 'file-saver';
import { CircularProgress } from '@mui/material';
import ReactConfetti from 'react-confetti';
import Modal from '@mui/material/Modal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/system';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import InfoIcon from '@mui/icons-material/Info';

const config = {
  angle: 90,
  spread: 45,
  startVelocity: 45,
  elementCount: 70,
  decay: 0.9
};

const Client = () => {
  const downloadButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [error, setError] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [original, setOriginal] = useState('');
  const [username, setUsername] = useState('');
  const [expiration, setExpiration] = useState('');
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [open, setOpen] = useState(true);
  const [smallScreen, setSmallScreen] = useState(false);
  const [openSmall, setOpenSmall] = useState(false);

  const logActivity = async (type, details = {}) => {
    const token = localStorage.getItem('token');
    try {
        await fetch('https://www.api.kenankorn.com/client/track', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ type, details })
        });
    } catch (error) {
        console.error('Activity logging error:', error);
    }
};

  useEffect(() => {
    const handleResize = () => {
      const isSmall = window.innerWidth < 800;
      setSmallScreen(isSmall);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setOpenSmall(smallScreen);
  }, [smallScreen]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    if (!expiration) {
      return {};
    }
  
    const difference = new Date(expiration).getTime() - new Date().getTime();
    let timeLeft = {};
  
    if (difference > 0) {
      const hours = Math.floor(difference / (1000 * 60 * 60));
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);
  
      timeLeft = {
        hours: hours < 10 ? `0${hours}` : hours,
        minutes: minutes < 10 ? `0${minutes}` : minutes,
        seconds: seconds < 10 ? `0${seconds}` : seconds,
      };
    }
  
    return timeLeft;
  }
  

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setSuccess(false);
  };

  const handleSmallClose = () => {
    setOpenSmall(false);
    setSmallScreen(false);
  };

  const handleMouseEnter = () => {
    if (downloadButtonRef.current) {
      downloadButtonRef.current.style.transition = 'opacity 3s';
      downloadButtonRef.current.style.opacity = 1;
    }
  };

  const handleMouseLeave = () => {
    if (downloadButtonRef.current) {
      downloadButtonRef.current.style.transition = 'opacity 3s';
      downloadButtonRef.current.style.opacity = 0;
    }
  };

  const downloadImage = async (url) => {
    setDownloading(true);
    try {
      const storagePathMatch = url.match(/o\/(.*?)\?/);
      if (!storagePathMatch) throw new Error('Invalid storage URL');
      
      const storagePath = decodeURIComponent(storagePathMatch[1]);
      
      await logActivity('FILE_DOWNLOAD', { 
        filename: storagePath.split('/').pop(),
        fileType: 'single'
      });

      const response = await fetch(
        `https://www.api.kenankorn.com/download/${original}?filename=${encodeURIComponent(url)}`,
        {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        }
      );

      if (!response.ok) throw new Error('Download failed');

      const blob = await response.blob();
      const fileName = storagePath.split('/').pop();
      saveAs(blob, fileName);
      setDownloading(false);
      setSuccess(true);
    } catch (error) {
      console.error('Download error:', error);
      setDownloading(false);
    }
  };

  

  const downloadAllImages = async () => {
    setDownloading(true);
    try {
      await logActivity('FILE_DOWNLOAD', { 
        fileType: 'bulk',
        totalFiles: images.length 
      });

      const response = await fetch(`https://www.api.kenankorn.com/downloadAll/${original}`);
      const blob = await response.blob();
      saveAs(blob, "images.zip");
      setDownloading(false);
      setSuccess(true);
    } catch (error) {
      console.error('Bulk download error:', error);
      setDownloading(false);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const token = localStorage.getItem('token');
      
      try {
        const response = await fetch('https://www.api.kenankorn.com/client/dashboard', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) throw new Error('Not logged in');

        const data = await response.json();
        setImages(data.images);
        const username = data.username.charAt(0).toUpperCase() + data.username.slice(1);
        setUsername(username);
        setOriginal(data.username);
        document.title = `${username}'s Dashboard`;

        const expirationDate = new Date(data.expiration.seconds * 1000 + data.expiration.nanoseconds / 1000000);
        expirationDate.setDate(expirationDate.getDate() + 1);
        setExpiration(expirationDate.toUTCString());

        await logActivity('GALLERY_VIEW', {
          imageCount: data.images.length,
          deviceType: window.innerWidth < 800 ? 'mobile' : 'desktop'
        });
      } catch (error) {
        console.error(error);
        window.location.href = '/client/login';
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  

  const extensionRequest = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://www.api.kenankorn.com/client/extension/${original}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        setLoading(false);
        alert("Extension has been requested!");
      } else {
        setLoading(false);
        alert("Error changing access time");
      }
    } catch (error) {
      setLoading(false);
      alert("Unknown error occurred.");
      console.log(error);
    }
  };

  function scrollImages() {
    document.querySelector('.client-images').scrollIntoView({
      behavior: 'smooth'
    });
  }

  return (
        <div className='client-wrapper'>
            {success && 
            <>
                <ReactConfetti 
                    active={ success } 
                    className='confetti-success'
                />
                <div className='success-modal'>
                        <Modal
                        open={success}
                        onClose={handleClose}
                        aria-labelledby="success-modal-title"
                        aria-describedby="success-modal-description"
                        className='success-modal'
                        >
                        <Box className="modalBox">
                            <CheckCircleIcon className="checkIcon" />
                            <h2 className="success-modal-title">Image(s) downloaded successfully!</h2>
                            <p className="success-modal-description">Enjoy!!!</p>
                        </Box>
                        </Modal>
                </div>
                </>
            }
           
            {loading && 
                <div className="loading-client">
                    <CircularProgress />
                </div>
            }
            {downloading && 
                <div className="loading-download">
                    <CircularProgress />
                    <div className="loading-text">
                        Downloading...
                    </div>
                </div>
            }
            {!loading && 
                <div className="client-header">
                    <div className="client-header-img">
                        <img src={Header} alt="Client Header" className="client-header-image" />
                    </div>
                    <div className="client-text">
                        <div className="client-smaller">
                            <div className="client-title">Welcome, <span className="name">{username}</span></div>
                            <p className="client-header-subtitle">
                                You can view your images here. You will have <b></b> to {timeLeft.hours ? `${timeLeft.hours} hours ` : null}
                                    {timeLeft.minutes ? `${timeLeft.minutes} minutes ` : null}
                                    {timeLeft.seconds ? `${timeLeft.seconds} seconds` : "0 seconds"} to download and view the images before they are deleted and client access is terminated. <div className='wrapper-request'>Request an extension: <div className="extension" onClick={extensionRequest}>here</div></div>
                                <br></br>
                                Hover/tap to download individual images.
                            </p>
                            <div className="actions">
                                <button className="download-button" onClick={() => 
                                {
                                    downloadAllImages().then(() => {
                                        setSuccess(true);
                                        setDownloading(false);
                                    }).catch((error) => {
                                        console.log(error);
                                    });
                                } 
                                }>
                                    Download <DownloadIcon />
                                </button>
                                <button className="scroll-btn-client" onClick={scrollImages}>
                                    Images <VisibilityIcon />
                                </button>
                            </div>
                            <div className="timer">
                                <div className="timer-text">
                                    {timeLeft.hours ? `${timeLeft.hours} hours ` : null}
                                    {timeLeft.minutes ? `${timeLeft.minutes} minutes ` : null}
                                    {timeLeft.seconds ? `${timeLeft.seconds} seconds` : "0 seconds"}
                                </div>
                                
                                <div className="subtitle" id="subtitle">
                                    Until images are deleted
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            }
            
            <div className="client-images">
                {!loading &&
                    <>
                        <div className="image-grid">
                            {images.map((image, i) => (
                                <div className="image-wrapper-client"
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <div className="client-image-img">
                                        <LazyLoadImage
                                            wrapperClassName='client-image-img'
                                            className='client-image-img'
                                            src={images[i]}
                                            visibleByDefault={"https://wtwp.com/wp-content/uploads/2015/06/placeholder-image.png"}
                                        />
                                    </div>
                                    <div className="download-button-client">
                                        <button
                                            ref={downloadButtonRef}
                                            className="download-button-btn"
                                            onClick={() => 
                                                {
                                                    downloadImage(image).then(() => {
                                                        setSuccess(true);
                                                        setDownloading(false);
                                                    }).catch((error) => {
                                                        console.log(error);
                                                    });
                                                } 
                                            }
                                        >
                                            <DownloadIcon
                                                fontSize='large'
                                                className='download-icon'
                                            />
                                        </button>
                                    </div>
                                </div>

                            ))}
                        </div>
                    </>
                }

            </div>
        </div>
    )
}

export default Client
import { useState } from 'react'
import './footer.scss'
import Contact from '../contact/Contact'
import SendIcon from '@mui/icons-material/Send';
import InstagramIcon from '@mui/icons-material/Instagram';

function Footer() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const openInstagram = () => {
    window.open('https://www.instagram.com/kenan_ko_/', '_blank')
  }

  return (
    <div className='footer'>
        <div className="centeredText">
            <div className="footerTextMain">
                <h2 className='cursive-footer'>Kenan Korn Photography</h2> © 2025
            </div>
            <div className="contact-button">
              <div className="contact-support">
                <button onClick={handleOpen} className='contact-btn'><SendIcon /></button>
                <p className='context'>Contact Me</p>
              </div>
              <div className="instagram">
                <button onClick={openInstagram} className='contact-btn'><InstagramIcon /></button>
                <p className='context'>Instagram</p>
              </div>
              
            </div>
        </div>
        <Contact open={open} handleClose={handleClose} />
    </div>
  )
}

export default Footer